import React from 'react'
import Card1Img from "../assets/features-card1.svg";
import Card2Img from "../assets/features-card2.svg";
import Card3Img from "../assets/features-card3.png";
import Card4Img from "../assets/features-card4.svg";

const title0 = "The features I present";
const caption0 = "to make the process as simple as making Maggie";
const title1 = "Contacting made easy";
const caption1 = "Communicate immediately, effectively, and effortlessly with anyone you want.";
const title2 = "Post anything about -";
const caption2 = "Local Insights, Market Updates, Tenant Reviews, Landlord Reviews, Group Buying, Decision Support, Community Updates and many more in real estate.";
const title3 = "Search for anything ";
const caption3 = "Do search not only for properties but also for posts and people.";
const title4 = "Choose your reliable tenant";
const caption4 = "Check the tenant's details before he/she visits your property.";

const InfoDiv = (props)=>{
    return (
        <div className='flex flex-col items-start p-8'>
            <div className=' text-[#131313] text-2xl mt-8 mb-5'>{props.title}</div>
            <p className='text-[#7F8090] text-base leading-relaxed tracking-wide '>{props.caption}</p>
        </div>
    )
}

export default function Features() {
    return (
        <div>
           
            <div className='text-center max-md:mb-20'>
                <div className='max-md:text-3xl max-md:mb-8 text-6xl text-[#24272C] mt-40'>{title0}</div>
                <p className='text-base text-gray-500 mt-5 tracking-wide md:text-xl'>{caption0}</p>
            </div>           

            <div class=" grid grid-cols-1 m-4 md:grid-cols-2 gap-4 md:m-28">
                <div class="grid gap-4">
                    <div className=' flex-col border-2 border-gray-200 rounded-3xl '>
                        <div className='flex justify-center'><img alt='card-pic' src={Card4Img} className='size-4/6 pt-8'/></div>
                        <InfoDiv title={title4} caption={caption4} />
                    </div>

                    <div className=' flex-col border-2 border-gray-200 rounded-3xl'>
                        <div className='flex justify-center'><img alt='card-pic' src={Card3Img} className='size-4/6 pt-8'/></div>
                        <InfoDiv title={title3} caption={caption3} />
                    </div>
                </div>
        
                <div class="grid gap-4">
                    <div className=' flex-col border-2 border-gray-200 rounded-3xl'>
                        <div className='flex justify-center'><img alt='card-pic' src={Card2Img} className='size-4/6 pt-8'/></div>
                        <InfoDiv title={title2} caption={caption2} />
                    </div>
        
                    <div className=' flex flex-col-reverse md:flex-col border-2 border-gray-200 rounded-3xl'>
                        <InfoDiv title={title1} caption={caption1}/>
                        <div className='flex justify-center'><img alt='card-pic' src={Card1Img} className='size-3/4 pt-8'/></div>
                    </div> 
                </div>
            </div>  
        </div>
  )
}