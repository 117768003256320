import React from 'react'
import mobileApp from "../assets/mobile-in-hand.svg"
import mobileAppSm from "../assets/mobile-in-hand-sm.svg"
import Playstore from "../assets/android-logo-white.svg";
import Appstore from "../assets/appstore-logo-white.svg";

const heading="Download the app now to get started";
const content0="Give me 40 MB I will serve you for life.";
const content1="I am the first version, there will be many new versions with important features for you.";

const Download = () => {
  return (
    <div className="bg-[#D2F26B] flex flex-col p-10 pb-0 md:m-14 md:rounded-[50px] mt-40 md:mt-80">
        <div className="flex justify-around">
            <div className="md:flex-col self-center xl:w-2/5">
                <div className=" md:pb-14">
                    <div className="md:text-5xl  max-md:text-2xl font-gelasio pb-10">{heading}</div>
                    <div className="font-light text-2xl max-xl:text-sm">{content0}</div>
                    <div className="font-light text-2xl max-xl:text-sm">{content1}</div>
                </div>
                <div className="max-md:flex max-md:pr-2 flex justify-between">
                    <div className="md:flex gap-3 max-md:space-y-2 max-md:mt-10 md:pb-20">
                        <button className="btn-black group relative overflow-hidden group">
                            <img className="" src={Appstore} alt="logo" />
                            <p className="font-poppins tracking-wide">{"App store"}</p>
                            <div class="absolute h-full w-full flex items-center justify-center -bottom-10 backdrop-blur-xl bg-white/30 group-hover:bottom-0 group-hover:left-0 opacity-0 group-hover:opacity-100 duration-400">
                                <p className={`text-center  text-white text-md`}>Coming Soon</p>
                            </div> 
                        </button>
                        <button className="btn-black group relative overflow-hidden group" >
                            <img src={Playstore} alt="logo" />
                            <p className="font-poppins tracking-wide">{"Play store"}</p>
                            <div class="absolute h-full w-full flex items-center justify-center -bottom-10 backdrop-blur-xl bg-white/30 group-hover:bottom-0 group-hover:left-0 opacity-0 group-hover:opacity-100 duration-400">
                                <p className={`text-center  text-white text-md`}>Coming Soon</p>
                            </div>
                        </button>
                    </div>
                    <div className="md:hidden">
                        <img src={mobileAppSm} alt="mobile-in-hand-sm" />
                    </div>
                </div>
            </div>
            <img className="-mt-44 max-xl:hidden size-4/12" src={mobileApp} alt="" />
        </div>
    
    </div>
    
  )
}

export default Download