import './App.css';
import Hero from './Components/Hero';
import Wemake from './Components/wemake';
import Features from './Components/features';
import Contact from './Components/contact';
import Download  from './Components/download';
import Footer from './Components/footer';

function App() {
  return (
    <div className='overscroll-x-none'>
      <Hero/>
      <Wemake/>
      <Features/>
      <Contact/>
      <Download/>
      <Footer/>
    </div>
  );
}

export default App;
