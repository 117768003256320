import React from 'react'

export default function Contact() {
  return (
    <div className=''>
        <div className='m-4 mt-28 md:m-28 md:w-4/5'>
            <div className=' text-3xl mb-8 md:text-5xl text-[#24272C]'>Together with you we can do more</div>
            <div className= "flex justify-between flex-row md:flex ">
                <div className="max-md:hidden w-1/5">
                    <p className=' text-[#828282] py-4'>Office</p>
                    <p className='font-normal text-2xl'>Miyapur, Hyderabad, Telangana, 500049, India</p>
                </div>
                <div className=" ">
                    <p className='text-[#828282] py-4'>Connection</p>
                    <p className='font-normal text-2xl max-md:text-base'>novizit@gmail.com</p>
                </div>
                <div className="flex flex-col  ">
                    <p className='text-[#828282] py-4 '>Social media</p>
                    <a href='/' className='font-normal text-2xl pb-4 max-md:text-base'>Instagram</a>
                    <a href='/' className='font-normal text-2xl max-md:text-base'>Linkedin</a>
                </div>
            </div>
        </div>
    </div>
  )
}