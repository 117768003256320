import React from 'react'
import Playstore from "../assets/android-logo.svg";
import Appstore from "../assets/appstore-logo.svg";
import Logo from "../assets/logo.png";
import BgRight from "../assets/hero-left.png";
import BgLeft from "../assets/hero-right.png";
const heroHeading = "A new way to find a property and tenant.";
const heroCaption = "Quickly: Select, communicate, and visit a property and a reliable tenant.";
export default function Hero() {
  return (
    <div className="">
        <div className={" top-0 left-0 right-0 flex flex-col items-center justify-center"}>
            <img className='w-1/5 max-md:w-52 mt-28 mb-20' src={Logo} alt='logo'/>           
            <div className="w-1/2 text-center text-zinc-600 text-6xl font-bold max-md:text-[32px] max-md:w-4/5">{heroHeading}</div>
            <div className="md:w-1/2 text-zinc-500 text-xl mt-5 text-center mb-24">{heroCaption}</div>            
            <div className={` md:flex `}>
                <button className='flex bg-[#d0ff39] rounded-full p-3.5 md:mr-5 max-md:mb-5 mb-24 relative overflow-hidden group'>
                    <img className='pr-2' src={Appstore} alt='logo'/>
                    <p className={` text-zinc-800 text-xl font-bold`}>Download on the App Store</p>
                    <div class="absolute h-full w-full flex items-center justify-center -bottom-10 backdrop-blur-md bg-white/30 group-hover:bottom-0 group-hover:left-0 opacity-0 group-hover:opacity-100 duration-400">
                        <p className={`text-center  text-zinc-800 text-xl font-bold`}>Coming Soon</p>
                    </div>
                </button>
                
                <button className='flex bg-[#d0ff39] rounded-full p-3.5 md:mr-5 max-md:mb-5 mb-24 relative overflow-hidden group'>
                    <img className='pr-2' src={Playstore} alt='logo'/>
                    <p className={` text-zinc-800 text-xl font-bold`}>Download on the Play Store</p>
                    <div class="absolute h-full w-full flex items-center justify-center -bottom-10 backdrop-blur-md bg-white/30 group-hover:bottom-0 group-hover:left-0 opacity-0 group-hover:opacity-100 duration-400">
                        <p className={`text-center  text-zinc-800 text-xl font-bold`}>Coming Soon</p>
                    </div>
                </button>
               

            </div>
            
        </div>
        <div className={`absolute right-0 top-0 size-1/5 max-md:hidden `}>
            <img src={BgLeft} alt='bg'/>
        </div>
        <div className={`absolute left-0 top-0 size-1/5 max-md:hidden `}>
            <img src={BgRight} alt='bg'/>
        </div>
    </div>
  )
}
