import React from 'react'
import Card1Img from "../assets/we-card1.png";
import Card2Img from "../assets/we-card2.svg";
import Card3Img from "../assets/we-card3.png";

const heading0 = "I was born to solve your problems, let me do these";
const heading1 = "You choose your <strong>tenant</strong>";
const caption1 = `<strong>Landlord</strong>, you <strong>won't</strong> lose the <strong>best tenant</strong> to your neighbors by viewing the posts.`;
const heading2 = "I never ask a single rupee for <strong>listing your property<strong>";
const caption2 = `Owners,<strong> list</strong> your property for <strong> sale or rent</strong> with us, opening doors to a <strong> wider audience</strong> `;
const heading3 = "This time I will help you <strong>find the best property.</strong>";
const caption3 = `Whether you're looking for a <strong>starter home</strong> or a <strong>luxury mansion</strong>, I've got the keys to your perfect place, offering options to <strong>buy and rent</storng> to suit every <strong>budget and lifestyle.</strong>`;

export default function wemake() {
    return (
        
        <div className='flex flex-col items-center '>
            <div className='xl:w-4/5'>
                <div className='flex items-center justify-center text-center '>
                    <div className='max-md:text-3xl max-xl:mt-72 mb-20 md:mb-32 md:w-4/5 text-zinc-800 text-6xl leading-[62.40px] md:mt-96'>{heading0}</div>
                </div>               
        
                <div className='max-md:flex-col max-md:px-4 max-md:mb-5 bg-[#F7F7EF] flex md:px-20 py-6 xl:rounded-3xl mb-12 md:justify-between'>
                    <div className='self-center'>
                        <div className='max-md:text-2xl max-md:mb-2.5 text-4xl mb-11' dangerouslySetInnerHTML={{ __html: heading1 }}/>
                        <div className='max-md:mb-10 md:w-4/5 mb-20 text-[#575757] text-base' dangerouslySetInnerHTML={{ __html: caption1 }} />                        
                        <div className='max-xl:hidden grid grid-cols-2 gap-5 place-items-start w-3/4'>
                            <div className='flex items-center '>
                                <div className='bg-white mr-3 rounded-full w-6 h-6'></div>
                                <p>See tenant posts</p>
                            </div>
                            <div className='flex items-center'>
                                <div className='bg-white mr-3 border-[#d2f26b] border-2 rounded-full w-6 h-6'></div>
                                <p>View profile</p>
                            </div>
                            <div className='flex items-center'>
                                <div className='bg-white mr-3 border-[#d2f26b] border-4 rounded-full w-6 h-6'></div>
                                <p>Contact</p>
                            </div>
                            <div className='flex items-center'>
                                <div className='mr-3 bg-[#d2f26b] rounded-full w-6 h-6'></div>
                                <p>Get a reliable tenant</p>
                            </div>                            
                        </div>
                    </div>
                    <img alt='card' src={Card1Img} className={`self-center md:size-2/6`} />
                </div>
            

                <div className='max-md:flex-col-reverse max-md:px-4 max-md:mb-5 bg-[#F7F7EF] flex md:px-20 py-6 md:py-14 xl:rounded-3xl mb-12 md:justify-between '>
                    <img alt='card' src={Card2Img} className={`self-center`} />
                    <div className='self-center md:pl-20'>
                        <div className=' max-md:text-2xl max-md:mb-2.5 text-4xl mb-11 'dangerouslySetInnerHTML={{ __html: heading2 }}/>
                        <div className='max-md:mb-10 mb-20 text-[#575757] text-base' dangerouslySetInnerHTML={{ __html: caption2 }} />                       
                        <div className='max-xl:hidden grid gap-5 grid-cols-2 w-3/4'>
                            <div className='flex items-center'>
                                <div className='mr-3 bg-[#d2f26b] rounded-full w-6 h-6'></div>
                                <p>Sell Property</p>
                            </div>
                            <div className='flex items-center'>
                                <div className='mr-3 bg-[#d2f26b] rounded-full w-6 h-6'></div>
                                <p>Rent Property</p>
                            </div>
                        </div>                        
                    </div>
                </div>


                <div className='max-md:flex-col max-md:px-4 bg-[#F7F7EF] flex md:px-20 py-6 xl:rounded-3xl mb-10 md:py-14'>
                    <div className='self-center'>
                        <div className='max-md:text-2xl max-md:mb-2.5 text-4xl mb-11'dangerouslySetInnerHTML={{ __html: heading3 }}/>
                        <div className='max-md:mb-10 md:w-4/5 mb-20 text-[#575757] text-base' dangerouslySetInnerHTML={{ __html: caption3 }} />
                        
                        <div className='max-xl:hidden grid grid-cols-2 gap-5 place-items-start w-3/4'>
                            <div className='flex items-center '>
                                <div className='bg-white mr-3 rounded-full w-6 h-6'></div>
                                <p>Search</p>
                            </div>
                            <div className='flex items-center'>
                                <div className='bg-white mr-3 border-[#d2f26b] border-2 rounded-full w-6 h-6'></div>
                                <p>View property</p>
                            </div>
                            <div className='flex items-center'>
                                <div className='bg-white mr-3 border-[#d2f26b] border-4 rounded-full w-6 h-6'></div>
                                <p>Contact</p>
                            </div>
                            <div className='flex items-center'>
                                <div className='mr-3 bg-[#d2f26b] rounded-full w-6 h-6'></div>
                                <p>Visit</p>
                            </div>
                        </div>
                        
                    </div>
                    <img alt='card' src={Card3Img} className='self-center md:size-2/5 '/>
                </div>
            </div>
        </div>
  )
}
